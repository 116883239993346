.headerWithButton {
  display: flex;
  align-items: center;
}

.headerWithButton h3 {
  flex-grow: 1;
}

.headerWithButton .iconButton {
  margin-right: 5rem;
}
