.successToast {
  background-color: rgb(54, 201, 110) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.25) !important;
}

.errorToast {
  background-color: rgb(240, 89, 89) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.25) !important;
}
