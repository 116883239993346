.sc-layer-toggler {
  top: 24px;
  right: 24px;
  height: 33px;
  position: absolute;
  padding: 4px;
  opacity: 0.9;
  border-radius: 4px;
  display: table;
  z-index: 2;
  max-width: 100px;
  background-color: #ccc;
  color: #555;
}

.sc-layer-toggler:hover {
  cursor: pointer;
}

.sc-layer-toggler-layer.selected {
  background-color: #bbb !important;
}
