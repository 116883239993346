.sc-user-profile {
  position: absolute;
  left: auto;
  right: 10px;
}

.sc-user-profile > span {
  padding: 5px;
}

.userInfo {
  display: flex;
}

.userFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 1rem;
}
