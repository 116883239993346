.inputs {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.updateInfo {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5rem;
}
