main {
  margin-top: 2.5rem;
}

.customPaper {
  padding: 1rem;
  background-color: #f5f5f5 !important;
}

.pageTitle {
  margin-bottom: 1.25rem;
}

.iconAndText {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.accordionHeader {
  background-color: rgb(228, 228, 228) !important;
  border-bottom: 1px solid rgb(205, 205, 205) !important;
}
