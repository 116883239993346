.content {
  width: 90%;
  margin: 2rem auto;
  margin-top: 3rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 1rem;
}

.recipientForm {
  width: fit-content;
  max-width: 930px;
}

.recipientTable {
  margin-top: 2rem;
}
