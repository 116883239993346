.sc-map-container {
  background-color: rgb(238, 238, 238);
  overflow: hidden;
  min-width: 1px;
  min-height: 250px;
  /* left: 0px; */
  left: 65px;
  top: 52px;
  right: 0px;
  bottom: 0px;
  /* width: auto; */
  height: auto;
  padding: 0px;
  z-index: auto;
  border-top: 1px solid #bbbbbb;
  border-left: none !important;
  border-right: none;
  border-bottom: none;
  outline: none;
}

#map {
  margin: 1% !important;
  width: 98% !important;
  height: 98% !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
  transform: unset !important;
  page-break-after: none;
}
